import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "reactstrap"
import marked from "marked"
import Moment from "react-moment"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Search from "../components/search/knowledgeSearch"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const LeftPanel = loadable(() =>
  pMinDelay(import("../components/panel/leftpanel"), 500)
)
var remark = require("remark")
var externalLinks = require("remark-external-links")
var html = require("remark-html")
const Banner = ({ date, title, posts }) => (
  <>
    <div className="hero-banner hero-knoweledgebase">
      <div className="container">
        <div className="hero-banner-content text-center">
          <h1 className="page-title font-weight-bold text-white mt-2">
            Knowledge Base
          </h1>
          <Search posts={posts} />
        </div>
      </div>
    </div>
  </>
)

const KnowledgeBaseTemplate = ({ location, data }) => {
  // const [mkd, setMkd] = React.useState()
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     remark()
  //       .use(externalLinks)
  //       .use(html)
  //       .process(post.body, function(err, file) {
  //         if (err) throw err
  //         setMkd(file)
  //         console.log("TT", String(file))
  //       })
  //   }, 5000)
  // }, [])
  const { post, categoryBase, allPosts } = data
  const posts = categoryBase.edges
  const allpost = allPosts.edges

  const topCat = posts.filter(
    ({ node }) => node.title === post.categories[0].title
  )

  const currentTitle = post.categories[0].category
    ? post.categories[0].category[0].title
    : post.categories[0].title
  const breadcrumbs = posts.filter(({ node }) => node.title === currentTitle)

  return (
    <Layout location={location} banner={<Banner posts={allpost} />}>
      <Seo title={post.metaTitle} description={post.metaDescription} />
      <div className="breadcrumbs">
        <div className="container">
          <ul className="list-unstyled">
            <li>
              <Link aria-label="link" to="/knowledge-base/">
                KnowledgeBase
              </Link>
            </li>
            {breadcrumbs.map((items, index) => {
              const subcategories = items.node.subCategory.filter(
                item => item.title === post.categories[0].title
              )
              const currentPage = items.node.knowledgebasepage.filter(
                item => item.title === post.title
              )
              return (
                <>
                  <li>
                    <Link
                      aria-label="link"
                      to={`/knowledge-base/${items.node.slug.current}/`}
                    >
                      {items.node.title}
                    </Link>
                  </li>
                  {subcategories ? (
                    <>
                      {subcategories.map((item, index) => {
                        const subPage = item.knowledgebasepage.filter(
                          item => item.title === post.title
                        )
                        return (
                          <>
                            <li>
                              <Link
                                to={`/knowledge-base/${item.slug.current}/`}
                              >
                                {item.title}
                              </Link>
                            </li>
                            {subPage.map((item, index) => {
                              return (
                                <li>
                                  <Link
                                    to={`/knowledge-base/${post.categories[0].slug.current}/${item.slug.current}/`}
                                  >
                                    {item.title}
                                  </Link>
                                </li>
                              )
                            })}
                          </>
                        )
                      })}
                    </>
                  ) : (
                    ""
                  )}

                  {currentPage ? (
                    <>
                      {currentPage.map((item, index) => {
                        return (
                          <li>
                            <Link
                              to={`/knowledge-base/${items.node.slug.current}/${item.slug.current}/`}
                            >
                              {item.title}
                            </Link>
                          </li>
                        )
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="page-content knowledge-single-page">
        <Container>
          <LazyLoadComponent>
            <div className="row">
              <div className="col-md-4">
                <div className="knowledgebase-sidebar">
                  <div className="knowledgebase-sidebar-page-list">
                    <p className="h4 font-weight-medium mt-0">
                      Articles In This Section
                    </p>
                    <div className="knowledge-sidebar-section h-100">
                      <ul className="knowledge-sidebar-list list-unstyled m-0">
                        {topCat.map(({ node }) => {
                          const pageTitle = node.knowledgebasepage.filter(
                            item => item.title !== post.title
                          )
                          return (
                            <>
                              {pageTitle.map((item, idx) => {
                                return (
                                  <li
                                    key={item.id}
                                    className="knowledge-link-level-0"
                                  >
                                    <div className="knowledge-subheading mb-2">
                                      <span className="knowledge-widget-icon fa fa-file-text-o mr-2 mt-1"></span>
                                      <Link
                                        activeClassName="current-active"
                                        className="font-weight-medium"
                                        to={`/knowledge-base/${node.slug.current}/${item.slug.current}/`}
                                      >
                                        {item.title}
                                      </Link>
                                    </div>
                                  </li>
                                )
                              })}
                            </>
                          )
                        })}
                        {posts.map(({ node }) => {
                          const subcategories = node.subCategory.filter(
                            item => item.title === post.categories[0].title
                          )
                          return (
                            <>
                              {subcategories.map((subcategory, idx) => {
                                const pageTitle = subcategory.knowledgebasepage.filter(
                                  item => item.title !== post.title
                                )
                                return (
                                  <>
                                    {pageTitle.map((item, idx) => {
                                      return (
                                        <li
                                          key={item.id}
                                          className="knowledge-link-level-0"
                                        >
                                          <div className="knowledge-subheading mb-2">
                                            <span className="knowledge-widget-icon fa fa-file-text-o mr-2 mt-1"></span>
                                            <Link
                                              activeClassName="current-active"
                                              className="font-weight-medium"
                                              to={`/knowledge-base/${subcategory.slug.current}/${item.slug.current}/`}
                                            >
                                              {item.title}
                                            </Link>
                                          </div>
                                        </li>
                                      )
                                    })}
                                  </>
                                )
                              })}
                            </>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                  <LeftPanel posts={posts} />
                </div>
              </div>
              <div className="col-md-8">
                <div className="knowledge-single-heading flex-column">
                  <h1 className="mt-0 font-weight-bold">{post.title}</h1>
                  <ul className="created list-unstyled">
                    <li>
                      <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                      <Moment
                        add={{ days: 1, hours: 9 }}
                        format="MMMM DD, YYYY"
                      >
                        {post.publishedAt ? post.publishedAt : post._createdAt}
                      </Moment>
                    </li>
                  </ul>
                </div>
                <div
                  className="d-block"
                  style={{ wordBreak: "break-word" }}
                  dangerouslySetInnerHTML={{
                    __html: marked(String(post.body)),
                  }}
                />
                <hr />
                <ul
                  className="list-unstyled"
                  style={{ wordBreak: "break-word" }}
                >
                  <li className="font-weight-medium">
                    Author:{" "}
                    {post.author !== null ? (
                      <>
                        <Link
                          className="font-weight-medium text-green"
                          to={`/author/${post?.author?.slug?.current}/`}
                        >
                          {post.author.name}
                        </Link>
                        {/* &nbsp;
                        {post.author.email ? (
                          <>
                            (
                            <a
                              aria-label="link"
                              href={`mailto:${post.author.email}`}
                            >
                              {post.author.email}
                            </a>
                            )
                          </>
                        ) : (
                          ""
                        )} */}
                      </>
                    ) : (
                      "Admin"
                    )}
                  </li>
                  {post.author?.email && (
                    <li className="font-weight-medium">
                      Email:{" "}
                      <a aria-label="link" href={`mailto:${post.author.email}`}>
                        {post.author.email}
                      </a>
                    </li>
                  )}
                  <li className="font-weight-medium">
                    Date Submitted:{" "}
                    {post.dateSubmitted ? post.dateSubmitted : post._createdAt}
                  </li>
                </ul>
              </div>
            </div>
          </LazyLoadComponent>
        </Container>
      </div>
    </Layout>
  )
}

export default KnowledgeBaseTemplate

export const knowledgeBaseQuery = graphql`
  query knowledgeBaseQueryTemp($slug: String!) {
    post: sanityKnowledgeBase(slug: { current: { eq: $slug } }) {
      id
      title
      publishedAt
      _createdAt
      metaTitle
      metaDescription
      body
      dateSubmitted(formatString: "l")
      slug {
        current
      }
      categories {
        ... on SanityKnowledgebasecategory {
          id
          title
          slug {
            current
          }
        }
        ... on SanitySubCategory {
          id
          title
          slug {
            current
          }
          category {
            id
            title
          }
        }
      }
      author {
        name
        email
        authorbio
        slug {
          current
        }
      }
    }
    category: sanityKnowledgebasecategory {
      id
      title
      subCategory {
        id
        title
        knowledgebasepage {
          id
          title
          categories {
            ... on SanityKnowledgebasecategory {
              id
              title
            }
            ... on SanitySubCategory {
              id
              title
            }
          }
        }
      }
      knowledgebasepage {
        id
        title
        categories {
          ... on SanityKnowledgebasecategory {
            id
            title
          }
          ... on SanitySubCategory {
            id
            title
          }
        }
      }
    }
    categoryBase: allSanityKnowledgebasecategory(
      sort: { fields: [order, knowledgebasepage___publishedAt], order: ASC }
    ) {
      edges {
        node {
          id
          title
          icon
          slug {
            current
          }
          subCategory {
            id
            title
            slug {
              current
            }
            knowledgebasepage {
              id
              title
              slug {
                current
              }
            }
          }
          knowledgebasepage {
            id
            title
            publishedAt
            _createdAt
            slug {
              current
            }
            categories {
              ... on SanityKnowledgebasecategory {
                id
                title
                slug {
                  current
                }
              }
              ... on SanitySubCategory {
                id
                title
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
    allPosts: allSanityKnowledgeBase {
      edges {
        node {
          id
          title
          metaDescription
          body
          slug {
            current
          }
          categories {
            ... on SanitySubCategory {
              id
              title
              slug {
                current
              }
            }
            ... on SanityKnowledgebasecategory {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
